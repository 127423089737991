import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import MainPage from './components/pages/MainPage';
import WelcomePage from './components/pages/WelcomePage';
import EmailPage from './components/pages/EmailPage';
import Game from './components/pages/Game';
import './App.css';


function App() {
  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();

    tg.expand();
    tg.setBackgroundColor('#000000');
  }, []);

  return (
    <div className="app-root">
      <Routes>
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/" element={<MainPage />} />
        <Route path="/email" element={<EmailPage />} />
        <Route path="/game" element={<Game />} />
      </Routes>
    </div>
  );
}

export default App;
